import React from "react"
import PropType from "prop-types"
import CustomerQuote from "./customer_quote"

const CustomerQuotes = ({ items }) => (
  <React.Fragment>
    <div className="container">
      {
        items.map(item => {
          const key = item.quote.document[0].id
          const data = item.quote.document[0].data
          const quotee = data.quotee.text
          const position = data.position.text
          const html_quote = data.quote.html
          const picture = data.picture

          return <CustomerQuote picture={picture} position={position} html_quote={html_quote} quotee={quotee} key={key} />
        })
      }
    </div>
  </React.Fragment>
)

CustomerQuotes.propTypes = {
  items: PropType.arrayOf(PropType.any).isRequired,
}

export default CustomerQuotes