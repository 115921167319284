import React from "react"
import PropType from "prop-types"
import styles from "./../customer_story.module.scss"

const CustomerStoryNormalHighlight = ({ highlighted_number, highlighted_number_explanation }) => (
  <div className={styles["highlight"]}>
    <h3 className='mt-spacing-16 mt-md-spacing-32'>{highlighted_number}</h3>
    <p className='small'> {highlighted_number_explanation}</p>
  </div>
)

CustomerStoryNormalHighlight.propTypes = {
  highlighted_number: PropType.string.isRequired,
  highlighted_number_explanation: PropType.string.isRequired,
}

export default CustomerStoryNormalHighlight