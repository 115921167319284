import React from "react"
import PropType from "prop-types"
import styles from "../big_cta/big_cta.module.scss"
import ForwardLink from "../../forward_link/forward_link"

const BigCTA = ({ text, link, size }) => (
  <div className=' mb-spacing-n48  mb-md-spacing-n96'>
    <div className={styles.seeMoreLinkBox}>
      <ForwardLink size={size} text={text} link={link} />
    </div>
  </div>
)

BigCTA.propTypes = {
  text: PropType.string.isRequired,
  link: PropType.any.isRequired,
  size: PropType.string.isRequired
}

export default BigCTA
