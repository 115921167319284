import React from "react"
import PropType from "prop-types"
import styles from "./see_more_link.module.scss"
import ForwardLink from "../../forward_link/forward_link"

const SeeMoreLink = ({ link, text }) => {
    return (
      <div className={`${styles.seeMoreLinkBox} cropped-content`}>
        <div className={`${styles.seeMoreLinkBoxContent}`}>
            <ForwardLink size="normal" text={text} link={link} />
        </div>
      </div>
    )
}

SeeMoreLink.propTypes = {
  link: PropType.any.isRequired,
  text: PropType.string.isRequired,
}

export default SeeMoreLink
