import React from "react"
import PropType from "prop-types"
import CustomerStory from "./customer_story"
import SeeMoreLink from "./see_more_link"

const CustomerStories = ({ items, primary }) => {
  const style = primary.style

  let count = 0;

  let content = items.map(item => {
    const key = item.customer.document[0].id
    const data = item.customer.document[0].data
    const illustration = data.illustration
    const involved_services = data.involved_services.map(service => service.service.document[0].data.name.text)
    const title = data.title.text
    const short_description_html = data.short_description.html
    const slices = data.body

    count += 1;

    return (
      <div className="col-12 mb-spacing-48 mb-md-spacing-96 col-md-6" key={key}>
        <CustomerStory
          style={style}
          illustration={illustration} slices={slices}
          involved_services={involved_services}
          short_description_html={short_description_html} title={title}
        />
      </div>
    )
  })

  if (primary.show_more_link_text && primary.show_more_link) {
    const show_more_link_text = primary.show_more_link_text.text
    const show_more_link = primary.show_more_link

    content.push(
      <div className="col-12 col-md-6 mb-spacing-48 mb-md-spacing-96" style={{ minHeight: "200px" }} key="SeeMoreLink">
        <SeeMoreLink text={show_more_link_text} link={show_more_link}/>
      </div>
    )
  }


  return (
    <div className="container">
      <div className="row mb-spacing-n48 mb-md-spacing-n96">
        {content}
      </div>
    </div>
  )
}

CustomerStories.propTypes = {
  items: PropType.arrayOf(PropType.any).isRequired,
  primary: PropType.any.isRequired,
}

export default CustomerStories