import React from "react"
import PropType from "prop-types"
import { Link } from "gatsby"
import styles from "./forward_link.module.scss"
import LinkResolver from "../../link_resolver"

const ForwardLink = ({ text, link, size }) => (
  <Link to={LinkResolver.resolveLink(link)} className={`${styles.forwardLink} ${styles[size]}`}>
    {text}

    <svg viewBox="0 0 32 32" className="animated-arrow">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-470.000000, -8.000000)">
          <g
             transform="translate(486.000000, 24.000000) rotate(-90.000000) translate(-486.000000, -24.000000) translate(462.000000, 0.000000)">
            <polygon id="Path" points="0 0 48 0 48 48 0 48"/>
            <polygon id="Path" fill="#276EF1" fillRule="nonzero"
                     points="40 24 37.18 21.18 26 32.34 26 8 22 8 22 32.34 10.84 21.16 8 24 24 40"/>
          </g>
        </g>
      </g>
    </svg>
  </Link>
)

ForwardLink.propTypes = {
  text: PropType.string.isRequired,
  link: PropType.any.isRequired,
  size: PropType.string.isRequired,
}

export default ForwardLink