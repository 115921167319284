import React from "react"
import PropType from "prop-types"
import Service from "./service"

const Services = ({ items }) => {
  let count = 0;
  let content = items
    .map(item => {
      let data = item.service.document[0].data
      let key = item.service.document[0].id

      count += 1;

      return <div className="col-12 mb-spacing-48 mb-md-spacing-96 col-md-6" data-aos="mobi-fade-up" data-aos-duration="400" data-aos-easing="ease-in-out-cubic" data-aos-delay={(count - 1 % 2) * 50} data-aos-offset={(count - 1 % 2) * 50} data-aos-once="true" key={key}>
        <Service description_html={data.description.html} name={data.name.text} illustration={data.illustration}/>
      </div>
    })


  return (
    <div className="container">
      <div className="row mb-spacing-n48 mb-md-spacing-n96">
        {
          content
        }
      </div>
    </div>
  )
}

Services.propTypes = {
  items: PropType.arrayOf(PropType.any).isRequired,
}

export default Services