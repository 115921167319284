import React from "react"
import PropType from "prop-types"
import styles from "../customer_story.module.scss"

const CustomerStoryRatingHighlight = ({ rating, rating_explanation }) => {
  const stars =
    <div className="pl-spacing-8" style={{ display: "inline-block" }}>
      <svg className={styles.allStars} width="128px" height="24px" viewBox="0 0 128 24">
        <defs>
          <path
            d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
            id="path-1"/>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-369.000000, -597.000000)">
            <g>
              <g transform="translate(0.000000, 562.000000)">
                <g transform="translate(309.000000, 18.000000)">
                  <g transform="translate(61.000000, 18.000000)">
                    <g transform="translate(104.000000, 0.000000)">
                      <g id="Rectangle">
                        <mask id="mask-2" fill="white">
                          <use xlinkHref="#path-1"/>
                        </mask>
                        <use id="Mask" stroke="#F7B500" strokeLinecap="round" strokeLinejoin="round"
                             xlinkHref="#path-1"/>
                        <rect fill="#F7B500" mask="url(#mask-2)" x="0" y="0" width="14" height="22"/>
                      </g>
                    </g>
                    <g id="rating-star-Copy-3" transform="translate(78.000000, 0.000000)" fill="#F7B500"
                       stroke="#F7B500" strokeLinecap="round" strokeLinejoin="round">
                      <path
                        d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
                        id="Path"/>
                    </g>
                    <g id="rating-star-Copy-2" transform="translate(52.000000, 0.000000)" fill="#F7B500"
                       stroke="#F7B500" strokeLinecap="round" strokeLinejoin="round">
                      <path
                        d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
                        id="Path"/>
                    </g>
                    <g id="rating-star-Copy" transform="translate(26.000000, 0.000000)" fill="#F7B500" stroke="#F7B500"
                       strokeLinecap="round" strokeLinejoin="round">
                      <path
                        d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
                        id="Path"/>
                    </g>
                    <g id="rating-star" fill="#F7B500" stroke="#F7B500" strokeLinecap="round" strokeLinejoin="round">
                      <path
                        d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
                        id="Path"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <svg className={styles.singleStar} width="18px" height="18px" viewBox="0 0 24 24">
        <defs>
          <path
            d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
            id="path-1"/>
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-369.000000, -597.000000)">
            <g>
              <g transform="translate(0.000000, 562.000000)">
                <g transform="translate(309.000000, 18.000000)">
                  <g transform="translate(61.000000, 18.000000)">
                    <g id="rating-star" fill="#F7B500" stroke="#F7B500" strokeLinecap="round" strokeLinejoin="round">
                      <path
                        d="M10.533,0.571 C10.6063043,0.376077078 10.792749,0.247 11.001,0.247 C11.209251,0.247 11.3956957,0.376077078 11.469,0.571 L14,7.747 L21.148,7.747 C21.3573498,7.74698389 21.5445449,7.87739216 21.6170734,8.07377688 C21.6896018,8.2701616 21.6321058,8.49093874 21.473,8.627 L15.5,13.579 L18,21.089 C18.068618,21.2954941 17.99581,21.5226574 17.8199405,21.6507909 C17.6440711,21.7789244 17.4055253,21.7786045 17.23,21.65 L11,17.079 L4.767,21.65 C4.59141031,21.7760435 4.35470816,21.7749888 4.18024871,21.6473855 C4.00578926,21.5197821 3.93307488,21.2945231 4,21.089 L6.5,13.579 L0.526,8.627 C0.366894161,8.49093874 0.309398174,8.2701616 0.381926621,8.07377688 C0.454455068,7.87739216 0.641650214,7.74698389 0.851,7.747 L8,7.747 L10.533,0.571 Z"
                        id="Path"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>

  return (
    <div className={styles["highlight"]}>
      <h3 className='mt-spacing-16 mt-md-spacing-32'>
        {rating}

        {stars}
      </h3>
      <p className='small'>{rating_explanation}</p>
    </div>
  )
}

CustomerStoryRatingHighlight.propTypes = {
  rating: PropType.number.isRequired,
  rating_explanation: PropType.string.isRequired,
}

export default CustomerStoryRatingHighlight