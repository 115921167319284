import React from "react";
import PropType from 'prop-types';
import styles from './customer_quote.module.scss';
import Img from "gatsby-image"

const CustomerQuote = ({quotee, position, picture, html_quote}) => (
  <div className="py-spacing-48 py-md-spacing-96">
    <div className="row justify-content-around">
      <h3 className="col-12 col-md-8" style={{textAlign: "center"}}>
        <div dangerouslySetInnerHTML={{ __html: html_quote }} />
      </h3>
    </div>

    <div className="mt-spacing-48 row justify-content-center align-items-center">
      <div className="col-auto">
        <Img fixed={picture.localFile.childImageSharp.fixed} alt={quotee} className="rounded-circle" />
      </div>
      <div className="col-auto">
        <span className={styles.customerQuoteName}>{quotee}</span>
        <br />
        {position}
      </div>
    </div>
  </div>
)

CustomerQuote.propTypes = {
  quotee: PropType.string.isRequired,
  position: PropType.string.isRequired,
  picture: PropType.any.isRequired,
  html_quote: PropType.string.isRequired
}

export default CustomerQuote