import React from "react"
import { graphql } from "gatsby"
import MainLayout from "../components/layout/main"
import BigCTA from "../components/slices/big_cta/big_cta"
import CustomerQuotes from "../components/slices/customer_quotes/customer_quotes"
import CustomerStories from "../components/slices/customer_stories/customer_stories"
import "../styles/main.scss"
import Services from "../components/slices/services/services"
import SEO from "../components/seo"

const LandingPage = ({ data: { prismicLandingPage: { data } } }) => (
  <MainLayout title={data.title.text} description={data.description.text} theme={data.hero_theme}>
    <SEO description={data.description.text} title={data.title.text} title_template={data.seo_custom_title_template} />
    {
      data.body
        .map(slice => {
          let component;

          switch (slice["__typename"]) {
            case "PrismicLandingPageBodyBigCta":
              component = <BigCTA text={slice.primary.link_text.text} size="big" link={slice.primary.link} />
              break;
            case "PrismicLandingPageBodyCustomerQuote":
              component = <CustomerQuotes items={slice.items}/>
              break;
            case "PrismicLandingPageBodyCustomerStories":
              component = <CustomerStories items={slice.items} primary={slice.primary}/>
              break;
            case "PrismicLandingPageBodyServices":
              component = <Services items={slice.items}/>
              break;
          }

          return <div className="slice" key={slice.id}>
            {component}
          </div>
        })
    }
  </MainLayout>
)

export default LandingPage
export const pageQuery = graphql`
  query LandingPageQuery ($id: String!) {
    prismicLandingPage(id: {eq: $id}) {
      data {
        title {
          text
        }
        description {
          text
        }
        seo_custom_title_template
        hero_theme
        body {
          __typename
          ... on PrismicLandingPageBodyBigCta {
            id
            primary {
              link_text {
                text
              }
              link {
                type
                document {
                  uid
                }
              }
            }
          }
          ... on PrismicLandingPageBodyServices {
            id
            items {
              service {
                document {
                  id
                  data {
                    illustration {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 584, maxHeight: 389) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    name {
                      text
                    }
                    description {
                      html
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyCustomerQuote {
            id
            items {
              quote {
                document {
                  id
                  data {
                    quotee {
                      text
                    }
                    position {
                      text
                    }
                    picture {
                      localFile {
                        childImageSharp {
                          fixed(width: 90) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    quote {
                      html
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageBodyCustomerStories {
            id
            primary {
              style
              show_more_link_text {
                text
              }
              show_more_link {
                type
                document {
                  uid
                }
              }
            }
            items {
              customer {
                document {
                  id
                  data {
                    illustration {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 578) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    title {
                      text
                    }
                    short_description {
                      html
                    }
                    involved_services {
                      service {
                        document {
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                    body {
                      __typename
                      ... on PrismicCustomerStoryBodyNormalHighlight {
                        id
                        primary {
                          highlighted_number {
                            text
                          }
                          highted_number_explanation {
                            text
                          }
                        }
                      }
                      ... on PrismicCustomerStoryBodyRatingHighlight {
                        id
                        primary {
                          rating
                          rating_explanation {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


`