import React from "react"
import PropType from "prop-types"
import CustomerStoryNormalHighlight from "./slices/normal_highlight"
import CustomerStoryRatingHighlight from "./slices/rating_highlight"
import Img from "gatsby-image"
import ScrollAnimation from 'react-animate-on-scroll';

const CustomerStory = ({ style, illustration, title, short_description_html, involved_services, slices }) => {
  let content
  if (style.toLowerCase() === "highlights") {
    content = <div className='row'>
      {
        slices.map(slice => {
          const key = slice.id
          const primary = slice.primary

          let component
          switch (slice["__typename"]) {
            case "PrismicCustomerStoryBodyNormalHighlight":
              const highlighted_number = primary.highlighted_number.text
              const highlighted_number_explanation = primary.highted_number_explanation.text
              component = <CustomerStoryNormalHighlight highlighted_number={highlighted_number}
                                                        highlighted_number_explanation={highlighted_number_explanation}/>

              break
            case "PrismicCustomerStoryBodyRatingHighlight":
              const rating = primary.rating
              const rating_explanation = primary.rating_explanation.text
              component = <CustomerStoryRatingHighlight rating={rating} rating_explanation={rating_explanation}/>
              break
          }

          return <div className='col-md-6 col-12' key={key}>{component}</div>
        })
      }
    </div>
  } else {
    content = <div dangerouslySetInnerHTML={{ __html: short_description_html }}/>
  }

  return (
    <React.Fragment>
      <div class="mb-spacing-24 cropped-content">
        <Img className={`animated-image`} fluid={illustration.localFile.childImageSharp.fluid} alt={title} />
        <div className="animated-image-overlay"></div>
      </div>
      <div className='h-companion mb-spacing-16'>{involved_services.join(" / ")}</div>
      <h3 className={"mb-spacing-8 mb-md-spacing-16"}>
        {title}
      </h3>

      {content}

    </React.Fragment>
  )
}

CustomerStory.propTypes = {
  style: PropType.any.isRequired,
  illustration: PropType.any.isRequired,
  title: PropType.string.isRequired,
  short_description_html: PropType.string.isRequired,
  involved_services: PropType.arrayOf(PropType.string).isRequired,
  slices: PropType.arrayOf(PropType.any),
}

export default CustomerStory